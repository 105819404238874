import {
	AppBar,
	Avatar,
	Box,
	Button,
	Chip,
	Container,
	Dialog,
	Grid,
	IconButton,
	Menu,
	MenuItem,
	Modal,
	Toolbar,
	Tooltip,
	Typography,
	keyframes,
	styled,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import {
	AreaChart,
	ArrowUpRight,
	BookOpenCheck,
	CalendarClock,
	Check,
	ChevronRight,
	CirclePlus,
	Download,
	DownloadCloud,
	ExternalLink,
	FilePenLine,
	LayoutDashboard,
	MenuIcon,
	PencilRuler,
	Play,
	Quote,
	Radar,
	Rocket,
	Route,
	Smile,
	X,
	Zap,
} from 'lucide-react';
import { useState } from 'react';
import TriageMintLogo from '../../assets/triage-logo-mint-white.svg';
import AntlerIcon from '../../assets/antler-icon.svg';
import AppPreview from '../../assets/app-preview.png';
import StealthIcon from '../../assets/stealth-icon.png';
import AmazonIcon from '../../assets/amazon-icon.jpeg';
import WorkdayIcon from '../../assets/workday-icon.webp';
import MongoIcon from '../../assets/mongo-icon.png';
import FooterCardBg from '../../assets/footer-card-bg.png';

import InstagramIcon from '../../assets/instagram-outline-icon.svg';
import MailIcon from '../../assets/mail-outline-icon.svg';
import LinkedInIcon from '../../assets/linkedIn-outline-icon.svg';

import Feature1 from '../../assets/feature1.png';
import Feature2 from '../../assets/feature2.png';
import Feature3 from '../../assets/feature3.png';
import Feature4 from '../../assets/feature4.png';

import ProductVideo from '../../assets/productVideo.mov';
import Collaborate from '../../assets/collaborate.png';

import BgDots from '../../assets/bg-dots.png';

import Marquee from 'react-fast-marquee';
import { useNavigate } from 'react-router-dom';
import { TOS } from './tos';
import { PrivacyPolicy } from './privacy';
import { ChipButton, OutlinedChipButton } from '../../App';

const pages = ['Demo', 'Features', 'Testimonials'];

const features = [
	{
		title: 'Build model',
		description: 'Craft a classifier quickly by answering a few questions about your operations.',
		image: Feature1,
	},
	{
		title: 'Fine-tune model',
		description:
			"Customize your model with your company's language, trained on your ticketing data.",
		image: Feature2,
	},
	{
		title: 'Test your model',
		description: "Utilize diverse testing environments to verify your classifier's accuracy.",
		image: Feature3,
	},
	{
		title: 'Add routing mechanisms',
		description:
			'Connect seamlessly to communication channels (Slack, email, etc.), directing tickets to the right experts instantly.',
		image: Feature4,
	},
];

const testimonials = [
	{
		description:
			'Ticket misallocation has been a recurring headache, but Triage AI dramatically improved our allocation accuracy.',
		position: 'Startup CEO',
		companyLogo: StealthIcon,
		company: 'Stealth',
	},
	{
		description:
			'Identifying sudden spikes in ticket volume is a struggle for us. Tools like Triage AI can accelerate our ability to detect and respond to such situations.',
		position: 'Operations Head',
		companyLogo: AmazonIcon,
		company: 'Amazon',
	},
	{
		description:
			'Our developers are spending excessive time dealing with out-of-scope tickets. Thanks to tools like Triage AI, we can reduce these inefficiencies.',
		position: 'Software Dev Manager',
		companyLogo: WorkdayIcon,
		company: 'Workday',
	},
	{
		description:
			'We often face challenges with long resolution times, impacting customer satisfaction. With solutions like Triage AI, we will observe significant enhancements in our performance metrics.',
		position: 'Support Lead',
		companyLogo: MongoIcon,
		company: 'MongoDB',
	},
];

const coreFeatures = [
	{
		icon: (
			<LayoutDashboard
				color="#D0FFD6"
				size={30}
			/>
		),
		title: 'Dashboard Reports',
		subtitle: 'Our rich and simple dashboard keeps you up-to-date on your help desk statistics',
	},
	{
		icon: (
			<FilePenLine
				color="#D0FFD6"
				size={30}
			/>
		),
		title: 'Configurable Help Topic',
		subtitle: 'Add, edit, and delete your help desk topics that suit your preference',
	},
	{
		icon: (
			<CalendarClock
				color="#D0FFD6"
				size={30}
			/>
		),
		title: 'Service Level Agreements',
		subtitle:
			'Simply set your business hours and our system will handle the rest leaving you worry-free',
	},
	{
		icon: (
			<Route
				color="#D0FFD6"
				size={30}
			/>
		),
		title: 'Ticket Filters',
		subtitle:
			'Our powerful ticket filtering system makes sure the right tickets goes to the right department leaving you with a clutter-free environment',
	},
	{
		icon: (
			<Smile
				color="#D0FFD6"
				size={30}
			/>
		),
		title: 'Customer Support Portal',
		subtitle:
			'Robust customer support portal system to help your business maintain happy customer relationships',
	},
	{
		icon: (
			<CirclePlus
				color="#D0FFD6"
				size={30}
			/>
		),
		title: 'And Much More!',
		subtitle: 'Triage AI comes pack with tons of awesome features you have to try out yourself',
	},
];

const advancedFeatures = [
	{
		icon: (
			<AreaChart
				color="#D0FFD6"
				size={30}
			/>
		),
		title: 'Advanced Analytics and Reporting',
		subtitle:
			'Gain deeper insights into ticket trends and performance metrics with advanced analytics and reporting tools',
	},
	{
		icon: (
			<BookOpenCheck
				color="#D0FFD6"
				size={30}
			/>
		),
		title: 'Sophisticated Testing Frameworks',
		subtitle:
			'Unlock the full potential of your ticket classifiers with our sophisticated testing frameworks',
	},
	{
		icon: (
			<Radar
				color="#D0FFD6"
				size={30}
			/>
		),
		title: 'Advanced Ticket Recognition',
		subtitle:
			'Optimize your ticket resolution process with our advanced ticket recognition feature',
	},
];

const ChipBubble = styled(Chip)(() => ({
	background: 'rgba(26, 74, 19, 0.35)',
	border: '1px solid #1A4A13',
	color: '#e8ffeb',
	paddingLeft: '20px',
	paddingTop: '10px',
	paddingRight: '20px',
	paddingBottom: '8px',
	'& .MuiChip-label': {
		padding: 0,
	},
}));

const ClickableAnchor = styled('a')(() => ({
	color: 'unset',
	textDecoration: 'none',
	display: 'flex',
	alignItems: 'flex-start',
	marginRight: '4px',
	'&:hover': {
		textDecoration: 'underline',
	},
}));

const FeatureItem = styled(Box)(() => ({
	minHeight: '480px',
	boxSizing: 'border-box',
	background: 'radial-gradient(circle, rgba(26, 74, 19, 0.25) 25%, rgba(26, 74, 19, 0.08) 100%)',
	border: '1px solid #0D250A',
	color: '#FFF',
	padding: '56px 24px',
	borderRadius: '55px',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'flex-end',
	position: 'relative',
}));

const TestimonialContainer = styled(Box)(() => ({
	minHeight: '280px',
	boxSizing: 'border-box',
	background: 'rgba(26, 74, 19, 0.05)',
	border: '1px solid rgba(26, 74, 19, 0.65)',
	color: '#FFF',
	padding: '24px 32px',
	borderRadius: '35px',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'flex-start',
	justifyContent: 'space-between',
	textAlign: 'left',
	marginLeft: '10px',
	marginRight: '10px',
}));

const CorporateAvatar = styled(Box)(() => ({
	border: '1px solid #FFF',
	width: '40px',
	height: '40px',
	borderRadius: '50px',
	marginRight: '14px',
}));

export const GradientSectionDivider = styled(Box)(() => ({
	width: '100%',
	height: '1px',
	background:
		'radial-gradient(circle at 50%, rgba(208, 255, 214, 0.7) 0%, rgba(208, 255, 214, 0) 100%)',
	marginTop: '170px',
	marginBottom: '112px',
}));

const pulseAnimate = keyframes`
	100% {
		opacity: 0;
		transform: scale(2);
	}
`;

const PulseCircle = styled(Box, {
	shouldForwardProp: prop => prop < 0,
})(({ index }) => ({
	position: 'absolute',
	width: '100%',
	height: '100%',
	background: '#2ECC71',
	opacity: 0.8,
	borderRadius: 'inherit',
	animation: `${pulseAnimate} 4s ease-out infinite`,
	animationDelay: `calc(1s * ${index})`,
}));

const StyledListItem = styled('li')(() => ({
	display: 'flex',
	alignItems: 'flex-start',
	marginBottom: '1rem',
	'&:last-child': {
		marginBottom: 0,
	},
	// '&:before': {
	// 	content: '"✓ "',
	// },
	// marginBottom: '10px',
}));

export const Landing = () => {
	const [anchorElNav, setAnchorElNav] = useState(null);
	const [open, setOpen] = useState(false);
	const [legalsOpen, setLegalsOpen] = useState(false);
	const [legalsType, setLegalsType] = useState('');

	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => setOpen(false);

	const handleLegalsOpen = type => {
		setLegalsOpen(true);
		setLegalsType(type);
	};
	const handleLegalsClose = () => {
		setLegalsOpen(false);
		setLegalsType('');
	};

	const navigate = useNavigate();
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	const navigateLearnMore = () => {
		navigate('/features');
	};

	const navigateSignIn = () => {
		navigate('/login');
	};

	return (
		<Container maxWidth="lg">
			<Box
				sx={{
					// height: 'calc(100vh - 72px)',
					textAlign: 'center',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					paddingTop: '90px',
					// justifyContent: 'center',
					// position: 'relative',
				}}
			>
				<ChipBubble
					label={
						<Typography
							variant="caption"
							sx={{
								fontSize: '0.8125rem',
								fontWeight: 600,
								p: 0,
								display: 'flex',
								alignItems: 'flex-start',
							}}
						>
							Backed by{' '}
							<ClickableAnchor
								href="https://www.antler.co/"
								target="_blank"
								rel="noopener noreferrer"
							>
								<img
									src={AntlerIcon}
									alt="Antler Icon"
									style={{ paddingTop: '1.5px', marginLeft: '4px' }}
								/>
								ntler,
							</ClickableAnchor>
							<ClickableAnchor
								href="https://www.microsoft.com/en-us/startups"
								target="_blank"
								rel="noopener noreferrer"
							>
								Microsoft,
							</ClickableAnchor>
							<ClickableAnchor
								href="https://aws.amazon.com/"
								target="_blank"
								rel="noopener noreferrer"
							>
								AWS
							</ClickableAnchor>
						</Typography>
					}
				/>

				<Typography
					variant="h1"
					sx={{
						fontSize: { xs: '3rem', sm: '4.5rem' },
						fontWeight: 600,
						lineHeight: 1.05,
						color: '#FFF',
						my: '24px',
						maxWidth: '800px',
					}}
				>
					Triage AI: New Era of Customer Support
				</Typography>

				<Typography
                    variant="h2"
                    sx={{
                        fontSize: { xs: '1.5rem', sm: '2rem' },
                        fontWeight: 400,
                        lineHeight: 1.3,
                        color: '#FFF',
                        my: '16px',
                        maxWidth: '800px',
                    }}
                >
                    Close your tickets faster, understand your business better, achieve happier customers
                </Typography>

				<Typography
					variant="subtitle1"
					sx={{
						fontSize: { xs: '1.125rem', sm: '1.375rem' },
						// fontWeight: 600,
						color: 'rgba(255, 255, 255, 0.6)',
						// opacity: 0.6,
						maxWidth: { xs: '80%', md: '65%' },
						margin: '0 auto',
						mb: '44px',
					}}
				>
					The{' '}
					<span
						style={{
							color: '#D0FFD6',
							opacity: 0.85,
							// textDecoration: 'underline',
							// textDecorationThickness: '1.5px',
							textShadow: '0px 0px 8px rgba(46, 204, 113, 0.8)',
							position: 'relative',
						}}
					>
						<b>Open Source Alternative</b>
					</span>{' '}
					to Zendesk
				</Typography>

				<ChipButton
					sx={{
						alignItems: 'flex-start',
						pr: 1.4,
					}}
					onClick={navigateLearnMore}
				>
					<Typography
						variant="subtitle1"
						sx={{
							color: '#000',
							fontWeight: 700,
							textTransform: 'none',
							width: 'fit-content',
							mr: 0.5,
						}}
					>
						Learn More
					</Typography>
					<ChevronRight color="#000" />
				</ChipButton>

				<Box
					id={pages[0]}
					sx={{
						width: '100%',
						pt: '120px',
						// paddingBottom: '58%',
						// boxSizing: 'border-box',
						// background: `url(${AppPreview})`,
						// backgroundRepeat: 'no-repeat',
						// backgroundPosition: 'top',
						// backgroundSize: '100%',
					}}
				>
					<Box
						sx={{ position: 'relative', cursor: 'pointer' }}
						onClick={handleOpen}
					>
						<Box
							sx={{
								width: '100%',
								height: '100%',
								background: 'rgba(204, 254, 212, 0.4)',
								position: 'absolute',
								left: '0',
								top: '0',
								filter: { xs: 'blur(15vh)', md: 'blur(30vh)' },
								zIndex: 0,
							}}
						/>

						<Box
							sx={{
								width: '100px',
								height: '100px',
								background: 'rgba(46, 204, 113, 0.25)',
								position: 'absolute',
								left: '50%',
								top: '50%',
								transform: 'translate(-50%, -50%)',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								borderRadius: '100px',
								zIndex: 2,
							}}
						>
							<Box
								sx={{
									width: '60px',
									height: '60px',
									background: '#1A4A13',
									border: '1px solid #FFF',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									borderRadius: '45px',
								}}
							>
								<Play style={{ fill: '#FFF', stroke: '#FFF', marginLeft: '2px' }} />
							</Box>
						</Box>
						<Typography
							variant="h2"
							sx={{
								fontSize: { xs: '1.2rem', sm: '1.5rem' },
								fontWeight: 400,
								lineHeight: 1.3,
								color: '#FFF',
								mt: '8px',
								mb: '8px',
								maxWidth: '800px',
								fontStyle: 'italic',
							}}
						>
							A Glimpse into Our Intelligent Classification and Routing Engine
							</Typography>
						<Box sx={{ height: '10px' }} />
						<img
							src={AppPreview}
							alt="Triage AI preview"
							style={{ width: '100%', position: 'relative', zIndex: 1 }}
						/>
					</Box>

					<GradientSectionDivider sx={{ mt: 18, mb: 1, opacity: 0.2 }} />

					<Box
						sx={{
							width: '100%',
							py: 9,
							boxSizing: 'border-box',
							background: `url(${BgDots})`,
							backgroundSize: 'cover',
							backgroundPosition: 'center',
							backgroundRepeat: 'no-repeat',
						}}
					>
						<Grid
							container
							spacing={{ xs: 7 }}
							maxWidth="lg"
							sx={{ margin: '0 auto' }}
						>
							<Grid
								xs={12}
								md={4}
								item
							>
								<Button
									sx={{
										display: 'flex',
										flexDirection: 'column',
										textTransform: 'unset',
										margin: '0 auto',
										py: '12px',
										my: '-12px',
										px: '16px',
										mx: '-16px',
										borderRadius: '12px',
									}}
									onClick={() =>
										window.open(
											'https://github.com/rayandabbagh/triage.ai.backend/releases',
											'_blank'
										)
									}
								>
									<DownloadCloud
										color="#FFF"
										size={30}
									/>
									<Typography
										variant="h5"
										sx={{
											fontWeight: 600,
											lineHeight: 1.3125,
											color: '#FFF',
											mb: 1,
										}}
									>
										Download
									</Typography>

									<Typography
										variant="subtitle2"
										sx={{
											color: '#FFF',
											opacity: 0.6,
											width: '70%',
											margin: '0 auto',
										}}
									>
										Visit our complete GitHub repository for free and explore our open-source project.
									</Typography>
								</Button>
							</Grid>

							<Grid
								xs={12}
								md={4}
								item
							>
								<Button
									sx={{
										display: 'flex',
										flexDirection: 'column',
										textTransform: 'unset',
										margin: '0 auto',
										py: '12px',
										my: '-12px',
										px: '16px',
										mx: '-16px',
										borderRadius: '12px',
									}}
									onClick={() => window.open('https://github.com/rayandabbagh/triage.ai.backend/blob/main/README.md', '_blank')}
								>
									<Zap
										color="#FFF"
										size={30}
										// strokeWidth={1.8}
									/>
									<Typography
										variant="h5"
										sx={{
											fontWeight: 600,
											lineHeight: 1.3125,
											color: '#FFF',
											mb: 1,
										}}
									>
										Ready to Use
									</Typography>

									<Typography
										variant="subtitle2"
										sx={{
											color: '#FFF',
											opacity: 0.6,
											width: '70%',
											margin: '0 auto',
										}}
									>
										Our documentation ensures you'll be up and running in less than 5 minutes.
									</Typography>
								</Button>
							</Grid>

							<Grid
								xs={12}
								md={4}
								item
								sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}
							>
								<Button
									sx={{
										display: 'flex',
										flexDirection: 'column',
										textTransform: 'unset',
										margin: '0 auto',
										py: '12px',
										my: '-12px',
										px: '16px',
										mx: '-16px',
										borderRadius: '12px',
									}}
									onClick={() => navigate('/cloud-hosting')}
								>
									<Rocket
										color="#FFF"
										size={30}
									/>
									<Typography
										variant="h5"
										sx={{
											fontWeight: 600,
											lineHeight: 1.3125,
											color: '#FFF',
											mt: '6.5px',
											mb: 1,
										}}
									>
										Cloud-hosted solution?
									</Typography>

									<Typography
										variant="subtitle2"
										sx={{
											color: '#FFF',
											opacity: 0.6,
											width: '70%',
											margin: '0 auto',
											// display: 'flex',
											// alignItems: 'flex-end',
											// justifyContent: 'center',
										}}
									>
										Try Triage<sup>+</sup> !
									</Typography>
								</Button>
							</Grid>
						</Grid>
					</Box>

					<GradientSectionDivider sx={{ mb: 2, mt: 1, opacity: 0.2 }} />

					<Dialog
						onClose={handleClose}
						open={open}
						fullScreen={fullScreen}
						PaperProps={{
							style: {
								backgroundColor: '#000',
								justifyContent: 'center',
								padding: '20px',
								borderRadius: '15px',
							},
						}}
						slotProps={{ backdrop: { style: { backgroundColor: 'rgba(5, 15, 4, 0.75)' } } }}
					>
						<IconButton
							onClick={handleClose}
							sx={{ justifyContent: 'flex-end' }}
						>
							<X
								color="#FFF"
								style={{ marginBottom: '20px', alignSelf: 'flex-end' }}
							/>
						</IconButton>
						<video
							controls
							autoPlay
						>
							<source
								src={ProductVideo}
								type="video/mp4"
							/>
							Your browser does not support HTML video.
						</video>
					</Dialog>
				</Box>

				<Box
					id={pages[1]}
					sx={{ width: { xs: '100%', sm: '80%', md: '60%' }, mt: '142px', pt: '50px' }}
				>
					<Typography
						variant="h2"
						sx={{
							fontSize: { xs: '2.5rem', sm: '3.5rem' },
							fontWeight: 600,
							lineHeight: 1.3125,
							color: '#FFF',
							mb: '44px',
						}}
					>
						Some of Our Core Features
					</Typography>

					{/* <Typography
						variant="h5"
						sx={{
							fontSize: { xs: '1.125rem', sm: '1.375rem' },
							color: '#FFF',
							opacity: 0.5,
							maxWidth: { xs: '80%', md: '65%' },
							margin: '0 auto',
							mt: '24px',
							mb: '44px',
						}}
					>
						Explore the Power of Triage AI's Cutting-Edge Capabilities
					</Typography> */}
				</Box>

				<Grid
					container
					spacing={7}
				>
					{coreFeatures.map(feature => (
						<Grid
							key={feature.title}
							xs={12}
							md={4}
							item
						>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'column',
									textTransform: 'unset',
									alignItems: 'center',
								}}
							>
								{feature.icon}

								<Typography
									variant="h5"
									sx={{
										fontWeight: 600,
										lineHeight: 1.3125,
										color: '#FFF',
										mt: 1,
										mb: 1,
									}}
								>
									{feature.title}
								</Typography>

								<Typography
									variant="subtitle2"
									sx={{
										color: '#FFF',
										opacity: 0.6,
										width: '90%',
										margin: '0 auto',
									}}
								>
									{feature.subtitle}
								</Typography>
							</Box>
						</Grid>
					))}

					{/* {features.map((feature, index) => (
						<Grid
							key={feature.title}
							xs={12}
							md={index + 1 === 1 || index + 1 === 4 ? 8 : 4}
							item
						>
							<FeatureItem>
								<Box
									sx={{
										width: '100%',
										height: '285px',
										position: 'absolute',
										top: index + 1 === 1 || index + 1 === 4 ? 0 : 15,
										paddingX: '24px',
										boxSizing: 'border-box',
									}}
								>
									<Box
										sx={{
											height: '100%',
											width: '100%',
											borderRadius: '31px',
											display: 'flex',
											justifyContent: 'center',
											overflow: 'hidden',
										}}
									>
										<img
											src={feature.image}
											alt={'Feature ' + index + 1}
											style={{
												width:
													index + 1 === 1 || index + 1 === 4 ? { xs: '205%', md: '100%' } : 'unset',
											}}
										/>
									</Box>
								</Box>
								<Box
									sx={{
										width: { xs: '90%', md: index + 1 === 1 || index + 1 === 4 ? '60%' : '80%' },
									}}
								>
									<Typography
										variant="h5"
										sx={{ fontWeight: 600, letterSpacing: '-0.08em', mb: 1.75 }}
									>
										{feature.title}
									</Typography>
									<Typography
										variant="subtitle1"
										sx={{ lineHeight: '1.5', opacity: 0.5 }}
									>
										{feature.description}
									</Typography>
								</Box>
							</FeatureItem>
						</Grid>
					))} */}
				</Grid>

				<OutlinedChipButton
					sx={{
						alignItems: 'flex-start',
						mt: 5,
						pr: 1.3,
					}}
					onClick={() =>
						navigate('/features', {
							state: {
								type: 'core',
							},
						})
					}
				>
					<Typography
						variant="subtitle1"
						sx={{
							fontWeight: 700,
							textTransform: 'none',
							width: 'fit-content',
							mr: 0.5,
						}}
					>
						View Core Features
					</Typography>
					{/* <ChevronRight color="#FFF" /> */}
				</OutlinedChipButton>

				<Box
					id={pages[1]}
					sx={{ width: { xs: '100%', sm: '80%', md: '60%' }, mt: '142px', pt: '50px' }}
				>
					<Typography
						variant="h2"
						sx={{
							fontSize: { xs: '2.5rem', sm: '3.5rem' },
							fontWeight: 600,
							lineHeight: 1.3125,
							color: '#FFF',
							mb: '44px',
						}}
					>
						Some of Our Advanced Features
					</Typography>
				</Box>

				<Grid
					container
					spacing={7}
				>
					{advancedFeatures.map(feature => (
						<Grid
							key={feature.title}
							xs={12}
							md={4}
							item
						>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'column',
									textTransform: 'unset',
									alignItems: 'center',
								}}
							>
								{feature.icon}

								<Typography
									variant="h5"
									sx={{
										fontWeight: 600,
										lineHeight: 1.3125,
										color: '#FFF',
										mt: 1,
										mb: 1,
									}}
								>
									{feature.title}
								</Typography>

								<Typography
									variant="subtitle2"
									sx={{
										color: '#FFF',
										opacity: 0.6,
										width: '90%',
										margin: '0 auto',
									}}
								>
									{feature.subtitle}
								</Typography>
							</Box>
						</Grid>
					))}
				</Grid>

				<OutlinedChipButton
					sx={{
						alignItems: 'flex-start',
						mt: 5,
						pr: 1.3,
					}}
					onClick={() =>
						navigate('/features', {
							state: {
								type: 'advanced',
							},
						})
					}
				>
					<Typography
						variant="subtitle1"
						sx={{
							fontWeight: 700,
							textTransform: 'none',
							width: 'fit-content',
							mr: 0.5,
						}}
					>
						View Advanced Features
					</Typography>
				</OutlinedChipButton>

				{/* <Typography
					id={pages[2]}
					variant="h1"
					sx={{
						fontSize: { xs: '3rem', sm: '4.5rem' },
						fontWeight: 600,
						lineHeight: 1.05,
						color: '#FFF',
						mt: '150px',
						pt: '50px',
						mb: '24px',
					}}
				>
					Beyond expectations
				</Typography>

				<Typography
					variant="h5"
					sx={{
						fontSize: { xs: '1.125rem', sm: '1.375rem' },
						color: '#FFF',
						opacity: 0.5,
						maxWidth: { xs: '80%', md: '65%' },
						margin: '0 auto',
						mb: '44px',
					}}
				>
					See how Triage AI has resonated with its audience
				</Typography>

				<Marquee
					pauseOnHover={true}
					style={{ width: '100vw' }}
				>
					{testimonials.map((testimony, index) => (
						<TestimonialContainer
							key={index}
							sx={{ maxWidth: { xs: '350px', sm: '390px', md: '400px' } }}
						>
							<Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
								<Typography
									variant="subtitle2"
									sx={{ mr: 2, opacity: 0.8 }}
								>
									{testimony.description}
								</Typography>

								<Quote
									style={{ fill: '#FFF', flexShrink: 0, paddingTop: 3 }}
									size={18}
								/>
							</Box>

							<Box sx={{ display: 'flex', alignItems: 'center' }}>
								<CorporateAvatar
									sx={{
										background: 'url(' + testimony.companyLogo + ')',
										backgroundSize: 'contain',
									}}
								/>

								<Box sx={{ display: 'flex', flexDirection: 'column' }}>
									<Typography
										variant="subtitle2"
										sx={{ lineHeight: 1.5 }}
									>
										{testimony.position}
									</Typography>

									<Typography
										variant="subtitle2"
										sx={{ lineHeight: 1.5, opacity: 0.75 }}
									>
										{testimony.company}
									</Typography>
								</Box>
							</Box>
						</TestimonialContainer>
					))}
				</Marquee> */}

				<Box>
					<Grid
						container
						spacing={4}
						sx={{ mt: '160px' }}
					>
						<Grid
							xs={12}
							md={6}
							item
						>
							<Box
								sx={{
									height: '100%',
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'space-between',
								}}
							>
								<Typography
									variant="h2"
									sx={{
										fontSize: { xs: '2rem', sm: '3rem' },
										fontWeight: 600,
										lineHeight: 1.05,
										color: '#FFF',
										textAlign: { xs: 'center', md: 'left' },
										width: { xs: '80%', md: 'unset' },
										margin: { xs: '0 auto', md: 'unset' },
									}}
								>
									We're not the only ones excited about Triage AI...
								</Typography>

								<ChipButton
									sx={{
										alignItems: 'flex-start',
										mt: 3,
										width: { xs: '80%', md: '85%' },
										margin: { xs: '24px auto', md: 'unset' },
									}}
									onClick={() =>
										window.open('https://calendly.com/rayan-dabbagh/triage-ai', '_blank')
									}
								>
									<Typography
										variant="subtitle1"
										sx={{
											color: '#000',
											fontWeight: 700,
											textTransform: 'none',
											mr: 0.5,
										}}
									>
										Schedule a Demo
									</Typography>
									<ArrowUpRight color="#000" />
								</ChipButton>
							</Box>
						</Grid>

						<Grid
							xs={12}
							md={6}
							item
						>
							<Typography
								variant="h5"
								sx={{
									fontSize: { xs: '1rem', sm: '1.125rem' },
									color: '#FFF',
									textAlign: 'left',
								}}
							>
								Hundreds of customers trust and use Triage AI for customer support.
								<ul style={{ listStyle: 'none', paddingLeft: 0, marginBottom: 0 }}>
									<StyledListItem>
										<Check
											style={{ flexShrink: 0, marginRight: 12, marginTop: 4 }}
											size={'1rem'}
											color={'#D0FFD6'}
										/>
										500+ developers using Triage AI
									</StyledListItem>

									<StyledListItem>
										<Check
											style={{ flexShrink: 0, marginRight: 12, marginTop: 4 }}
											size={'1rem'}
											color={'#D0FFD6'}
										/>
										50+ businesses using Triage AI
									</StyledListItem>

									<StyledListItem>
										<Check
											style={{ flexShrink: 0, marginRight: 12, marginTop: 4 }}
											size={'1rem'}
											color={'#D0FFD6'}
										/>
										Simple and easy-to-use web-based customer support platform
									</StyledListItem>

									<StyledListItem>
										<Check
											style={{ flexShrink: 0, marginRight: 12, marginTop: 4 }}
											size={'1rem'}
											color={'#D0FFD6'}
										/>
										Triage AI comes packed with more features and tools than most of the expensive
										(and complex) support ticket systems on the market
									</StyledListItem>

									<StyledListItem>
										<Check
											style={{ flexShrink: 0, marginRight: 12, marginTop: 4 }}
											size={'1rem'}
											color={'#D0FFD6'}
										/>
										The best part is that Triage AI is completely free
									</StyledListItem>
								</ul>
							</Typography>
						</Grid>
					</Grid>
				</Box>

				<Box
					sx={{
						width: '100%',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						pt: '240px',
					}}
				>
					<Box
						sx={{
							position: 'relative',
							width: '180px',
							height: '180px',
							borderRadius: '50%',
							zIndex: 5,
						}}
					>
						<Box
							sx={{
								position: 'absolute',
								width: '100%',
								height: '100%',
								background: `url(${Collaborate})`,
								backgroundSize: 'contain',
								borderRadius: '50%',
								zIndex: 2,
							}}
						/>
						<PulseCircle index={0}></PulseCircle>
						<PulseCircle index={1}></PulseCircle>
						<PulseCircle index={2}></PulseCircle>
						<PulseCircle index={3}></PulseCircle>
					</Box>

					<Typography
						variant="h1"
						sx={{
							fontSize: { xs: '2rem', sm: '3.5rem' },
							fontWeight: 600,
							lineHeight: 1.05,
							color: '#FFF',
							my: '24px',
							maxWidth: '80%',
							textAlign: 'center',
							mx: 'auto',
							marginTop: '100px',
						}}
					>
						Get The #1 Fastest Growing Customer Support Platform In The World.
					</Typography>

					<Typography
						variant="h1"
						sx={{
							fontSize: { xs: '1.125rem', sm: '1.375rem' },
							color: 'rgba(255, 255, 255, 0.6)',
							maxWidth: { xs: '80%', md: '65%' },
							margin: '0 auto',
							mb: '44px',
							textAlign: 'center',
						}}
					>
						At Triage AI, we're dedicated to crafting exceptional experiences for your customers.
						Empower your team to{' '}
						<span
							style={{
								color: '#D0FFD6',
								opacity: 0.85,
								textShadow: '0px 0px 8px rgba(46, 204, 113, 0.8)',
							}}
						>
							scale effortlessly
						</span>
						,{' '}
						<span
							style={{
								color: '#D0FFD6',
								opacity: 0.85,
								textShadow: '0px 0px 8px rgba(46, 204, 113, 0.8)',
							}}
						>
							streamline operations
						</span>
						, and{' '}
						<span
							style={{
								color: '#D0FFD6',
								opacity: 0.85,
								textShadow: '0px 0px 8px rgba(46, 204, 113, 0.8)',
							}}
						>
							elevate customer satisfaction
						</span>{' '}
						levels like never before.
					</Typography>
				</Box>
			</Box>
		</Container>
	);
};
