import { Box, Container, Typography } from '@mui/material';


export const CloudTrial = () => {
	return (
		<Container maxWidth="lg">
			<Box
				sx={{
					textAlign: 'center',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					paddingTop: '90px',
				}}
			>
				<Typography
					variant="h1"
					sx={{
						fontSize: { xs: '2.5rem', sm: '4rem' },
						fontWeight: 600,
						lineHeight: 1.05,
						color: '#FFF',
						my: '24px',
						maxWidth: '800px',
					}}
				>
					Coming Soon!
				</Typography>
				
				<Typography
					variant="h5"
					sx={{
						fontSize: { xs: '1.125rem', sm: '1.375rem' },
						color: 'rgba(255, 255, 255, 0.6)',
						maxWidth: { xs: '80%', md: '65%' },
						margin: '0 auto',
						mb: '44px',
						zIndex: 1
					}}
				>
					<br />
					For now, schedule a call with our triage team{' '}
					<a href="https://calendly.com/rayan-dabbagh/triage-ai" target="_blank" rel="noopener noreferrer">
						here
					</a>{' '}
					for a frictionless transition to the cloud.
				</Typography>

				<Typography
					variant="h5"
					sx={{
						fontSize: { xs: '1.125rem', sm: '1.375rem' },
						color: 'rgba(255, 255, 255, 0.6)',
						maxWidth: { xs: '80%', md: '65%' },
						margin: '0 auto',
						mb: '44px',
					}}
				>
					
					Estimated Release Date: 07/15/2024
				</Typography>
			</Box>
		</Container>
	);
};
